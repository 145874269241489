import { Component, Output, EventEmitter } from '@angular/core';

import { AuthService } from 'src/app/auth/services/auth.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent {
  @Output() toggleSidenav = new EventEmitter();

  constructor(public authService: AuthService) {}

  public logout() {
    this.authService.logout();
  }

  public toggle = () => {
    this.toggleSidenav.emit();
  };
}
