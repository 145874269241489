<div class="flex flex-column flex-fill">
  <app-header (toggleSidenav)="sidenav?.toggle()"></app-header>
  <div>
    <mat-sidenav-container>
      <mat-sidenav
        *ngIf="this.authService.isAuthenticated"
        #sidenav
        mode="side"
        [opened]="this.authService.isAuthenticated"
        (openedChange)="sidenavToggled()"
      >
        <app-side-nav></app-side-nav>
      </mat-sidenav>
      <mat-sidenav-content>
        <div class="app-content">
          <router-outlet></router-outlet>
        </div>
      </mat-sidenav-content>
    </mat-sidenav-container>
  </div>
</div>
