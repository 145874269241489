import { Component, Input, OnInit } from '@angular/core';
import { takeUntil } from 'rxjs';
import { DestroyComponent } from 'src/app/core/components/destroy/destroy.component';
import { DataState } from 'src/app/model/data-state.enum';
import { HostSelectionService } from 'src/app/services/host-selection.service';

@Component({
  selector: 'app-data-state',
  templateUrl: './data-state.component.html',
  styleUrls: ['./data-state.component.scss'],
})
export class DataStateComponent extends DestroyComponent implements OnInit {
  @Input() state: DataState = DataState.Loading;
  readonly StateType = DataState;

  public isOrganisationSelected: boolean = false;

  constructor(public hostSelectionService: HostSelectionService) {
    super();
  }

  ngOnInit() {
    this.hostSelectionService.query$
      .pipe(takeUntil(this.destroy$))
      .subscribe((query) => {
        this.isOrganisationSelected = query.organisation !== null;
      });
  }
}
