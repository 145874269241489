import { Injectable } from '@angular/core';
import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpErrorResponse,
} from '@angular/common/http';

import { catchError, Observable, switchMap, throwError } from 'rxjs';
import { AuthService } from '../services/auth.service';
import { environment } from 'src/environments/environment';

/** Pass untouched request through to the next request handler. */
@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  private retryCount = 0;

  public constructor(private readonly authService: AuthService) {}

  public intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    let headers = req.headers.set('X-Forwarded-Host', environment.host);
    if (this.authService.getToken()) {
      headers = headers.set(
        'Authorization',
        `Bearer ${this.authService.getToken()!}`
      );
    }

    req = req.clone({
      headers,
    });

    return next.handle(req).pipe(
      catchError((error: HttpErrorResponse) => {
        if (error.status === 401 && this.retryCount < 3) {
          if (!this.authService.getRefreshToken()) {
            this.authService.logout();
            return throwError(() => error);
          }
          this.retryCount++;
          return this.authService.refreshToken().pipe(
            switchMap(() => {
              headers = headers.set(
                'Authorization',
                `Bearer ${this.authService.getToken()!}`
              );
              req = req.clone({
                headers,
              });
              this.retryCount = 0;
              return next.handle(req);
            })
          );
        }
        this.retryCount = 0;
        return throwError(() => error);
      })
    );
  }
}
