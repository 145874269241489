import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { HostSelectionService } from './host-selection.service';

@Injectable({
  providedIn: 'root',
})
export class DevicesTableService {
  private selectedDevice = new BehaviorSubject<number | null>(null);
  private selectedRack = new BehaviorSubject<number | null>(null);

  setSelectedDevice(deviceId: number) {
    this.selectedDevice.next(deviceId);
  }

  getSelectedDevice() {
    return this.selectedDevice.asObservable();
  }

  setSelectedRack(deviceId: number) {
    this.selectedRack.next(deviceId);
  }

  getSelectedRack() {
    return this.selectedRack.asObservable();
  }
}
