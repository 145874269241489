import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { ActivatedRoute, NavigationStart, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import {
  catchError,
  concatMap,
  defer,
  EMPTY,
  filter,
  forkJoin,
  map,
  Observable,
  of,
  shareReplay,
  takeUntil,
  tap,
} from 'rxjs';
import { DestroyComponent } from 'src/app/core/components/destroy/destroy.component';
import { DevicesTableService } from 'src/app/services/devices-table.service';
import { HostSelectionService } from 'src/app/services/host-selection.service';
import { SelectionHiderService } from 'src/app/services/selection-hider.service';

@Component({
  selector: 'app-selection-menu',
  templateUrl: './selection-menu.component.html',
  styleUrls: ['./selection-menu.component.scss'],
})
export class SelectionMenuComponent extends DestroyComponent implements OnInit {
  @Input() public showSelectors: (
    | 'organisation'
    | 'host'
    | 'outlet'
    | 'feed'
    | 'asset'
    | 'date'
  )[] = ['organisation', 'host', 'outlet', 'feed', 'asset', 'date'];

  private selectedHost: number;
  private queryOrganisation: number;
  private queryHost: number;
  private queryOutlet: string;
  private queryFeed: 'feed 0' | 'feed 1' | null;
  private queryAsset: number;
  private queryDate: string;
  private queryCalls: Observable<void>[] = [];

  public hideOutlet$ = this.selectionHiderService.getIsOutletSelectorHidden();

  public periodKeys: any = [
    'date_today',
    'date_yesterday',
    'date_this_week',
    'date_last_week',
    'date_this_month',
    'date_last_month',
    'date_this_quarter',
    'date_last_quarter',
    'date_this_year',
    'date_last_year',
  ];
  public hostPlaceholder: string =
    this.translateService.instant('host_placeholder');
  public outletPlaceholder: string = this.translateService.instant(
    'host_outlet_placeholder'
  );
  public feedPlaceholder: string =
    this.translateService.instant('feed_placeholder');
  public assetPlaceholder: string =
    this.translateService.instant('asset_placeholder');

  public formGroup = this._fb.group({
    organisation: [null],
    host: [{ value: null, disabled: true }],
    outlet: [{ value: null, disabled: true }],
    feed: [{ value: null, disabled: true }],
    asset: [{ value: null, disabled: true }],
    date: [null],
  });

  constructor(
    public hostSelectionService: HostSelectionService,
    private readonly _fb: UntypedFormBuilder,
    private translateService: TranslateService,
    private route: ActivatedRoute,
    private router: Router,
    private devicesTableService: DevicesTableService,
    private selectionHiderService: SelectionHiderService
  ) {
    super();
  }

  ngOnInit() {
    this.hostSelectionService.organisations$ =
      this.hostSelectionService.getOrganisations();

    this.devicesTableService
      .getSelectedRack()
      .pipe(
        takeUntil(this.destroy$),
        filter((rackId) => rackId !== null),
        tap((rackId) => this.onSelectHost(rackId))
      )
      .subscribe();

    this.devicesTableService
      .getSelectedDevice()
      .pipe(
        takeUntil(this.destroy$),
        filter((deviceId) => deviceId !== null),
        tap((deviceId) => this.onSelectAsset(deviceId))
      )
      .subscribe();

    this.route.queryParams
      .pipe(
        takeUntil(this.destroy$),
        tap((params) => {
          this.queryOrganisation = +params['group'];
          this.queryHost = +params['device'];
          this.queryOutlet = params['outlet']?.replace(/-/g, ' / ');
          this.queryFeed = params['feed'];
          this.queryAsset = +params['asset'];
          this.queryDate = params['date'] ?? 'today';

          this.pushAllSelectedQueryCalls();
        }),
        concatMap(() => {
          return forkJoin(this.queryCalls).pipe(
            tap(() => {
              this.formGroup.patchValue(
                {
                  organisation: this.queryOrganisation,
                  host: this.queryHost,
                  outlet: this.queryOutlet,
                  feed: this.queryFeed,
                  asset: this.queryAsset,
                  date: this.queryDate,
                },
                { emitEvent: false }
              );
              this.queryCalls = [];
            })
          );
        })
      )
      .subscribe();
  }

  public onSelectOrganization(selectedValue: any) {
    this.hostSelectionService.deleteChildCache('organisation');

    this.router.navigate([], {
      queryParams: {
        group: selectedValue,
        device: null,
        outlet: null,
        feed: null,
        asset: null,
      },
      queryParamsHandling: 'merge',
    });
  }

  public onSelectHost(selectedValue: any) {
    this.hostSelectionService.deleteChildCache('host');

    this.router.navigate([], {
      queryParams: {
        device: selectedValue,
        outlet: null,
        feed: null,
        asset: null,
      },
      queryParamsHandling: 'merge',
    });
  }

  public onSelectHostOutlet(selectedValue: any) {
    const formattedValue = selectedValue?.replace(/\s*\/\s*/g, '-');

    this.router.navigate([], {
      queryParams: { outlet: formattedValue },

      queryParamsHandling: 'merge',
    });
  }

  public onSelectFeed(selectedValue: any) {
    this.hostSelectionService.deleteChildCache('feed');

    this.router.navigate([], {
      queryParams: { feed: selectedValue, asset: null },
      queryParamsHandling: 'merge',
    });
  }

  public onSelectAsset(selectedValue: any) {
    this.hostSelectionService.deleteChildCache('asset');

    this.router.navigate([], {
      queryParams: { feed: null, asset: selectedValue?.toString() },
      queryParamsHandling: 'merge',
    });
  }

  public onSelectPeriod(selectedValue: any) {
    this.router.navigate([], {
      queryParams: { date: selectedValue },
      queryParamsHandling: 'merge',
    });
  }

  public getOrganisationPlaceholder(hasValues: boolean) {
    return hasValues
      ? this.translateService.instant('organisation_placeholder')
      : this.translateService.instant('no_values');
  }

  private selectOrganization(selectedValue: any) {
    this.formGroup.get('host')?.disable();
    this.formGroup.get('outlet')?.disable();
    this.formGroup.get('feed')?.disable();
    this.formGroup.get('asset')?.disable();

    if (selectedValue) {
      this.formGroup.get('host')?.enable();
      this.hostSelectionService.hosts$ = this.getHosts(selectedValue).pipe(
        map((hosts) => {
          this.hostPlaceholder =
            this.translateService.instant('host_placeholder');
          return hosts;
        })
      );
    } else {
      this.hostPlaceholder = this.translateService.instant('no_values');
      this.formGroup.get('host')?.disable();
    }

    this.formGroup.patchValue({ outlet: null, host: null });
    this.hostSelectionService.setQuery({ organisation: selectedValue });
  }

  private selectHost(selectedValue: any) {
    this.formGroup.get('feed')?.disable();
    this.formGroup.get('asset')?.disable();

    this.selectedHost = selectedValue;
    // OLD OUTLET CODE. WE KEEP THIS FOR NOW TO KEEP CURRENT FUNCTIONALITIES
    this.formGroup.get('outlet')?.enable();
    this.hostSelectionService.hostOutlets$ = this.hostSelectionService
      .getHostOutlets(selectedValue)
      .pipe(
        tap((outlets) => {
          if (outlets.length > 0) {
            this.outletPlaceholder = this.translateService.instant(
              'host_outlet_placeholder'
            );
          } else {
            this.outletPlaceholder = this.translateService.instant('no_values');
          }
        }),
        shareReplay(),
        catchError(() => {
          this.outletPlaceholder = this.translateService.instant('no_values');
          this.formGroup.get('outlet')?.disable();
          return EMPTY;
        })
      );

    // NEW ASSET CODE
    this.hostSelectionService.allAssets$ =
      this.hostSelectionService.getAllAssets(selectedValue);

    this.formGroup.get('asset')?.enable();
    //Doesn't really matter which parameter we filter on. We just need the names
    const keyFilter = 'P_';
    const nameFilter = 'P - ';
    this.hostSelectionService.assets$ =
      this.hostSelectionService.allAssets$.pipe(
        map((assets) =>
          assets
            .filter((asset) => asset.key.startsWith(keyFilter))
            .map((asset) => ({
              ...asset,
              key: asset.key.replace(keyFilter, ''),
              name: asset.name.replace(nameFilter, ''),
            }))
        ),
        tap((assets) => {
          if (assets.length > 0) {
            this.assetPlaceholder =
              this.translateService.instant('asset_placeholder');
          } else {
            this.assetPlaceholder = this.translateService.instant('no_values');
          }
        }),
        shareReplay(),
        catchError(() => {
          this.assetPlaceholder = this.translateService.instant('no_values');
          this.formGroup.get('asset')?.disable();
          return EMPTY;
        })
      );

    // NEW FEEDS CODE
    // In a later stage we probably will get Feed names from the API
    this.formGroup.get('feed')?.enable();
    this.hostSelectionService.feeds$ = of([
      {
        name: 'Feed 0',
        value: 'feed 0',
      },
      {
        name: 'Feed 1',
        value: 'feed 1',
      },
    ]);
    // this.hostSelectionService.feeds$ = this.hostSelectionService
    //   .getFeeds(selectedValue)
    //   .pipe(
    //     tap((feeds) => {
    //       if (feeds.length > 0) {
    //         this.feedPlaceholder =
    //           this.translateService.instant('feed_placeholder');
    //       } else {
    //         this.feedPlaceholder = this.translateService.instant('no_values');
    //       }
    //     }),
    //     shareReplay(),
    //     catchError(() => {
    //       this.feedPlaceholder = this.translateService.instant('no_values');
    //       this.formGroup.get('feed')?.disable();
    //       return EMPTY;
    //     })
    //   );

    this.hostSelectionService.setQuery({ host: selectedValue });
  }

  private selectHostOutlet(selectedValue: any) {
    this.hostSelectionService
      .getHostOutlets(this.selectedHost)
      .pipe(
        takeUntil(this.destroy$),
        map((outlets: any) =>
          outlets.find((outlet: any) => outlet.outletName === selectedValue)
        ),
        tap((outlet) => this.hostSelectionService.setQuery({ outlet: outlet })),
        filter((outlet) => outlet),
        shareReplay()
      )
      .subscribe((outlet: any) => {
        let vrmsObj = outlet.items.find(
          (foundItem: { parameter: string }) => foundItem.parameter === 'Vrms'
        );
        if (vrmsObj) {
          this.hostSelectionService.setQuery({ outletVoltage: selectedValue });
        }
        let vthdObj = outlet.items.find(
          (foundItem: { parameter: string }) => foundItem.parameter === 'VTHD'
        );
        if (vthdObj) {
          this.hostSelectionService.setQuery({
            outletVoltageTHD: selectedValue,
          });
        }
      });
  }

  private selectFeed(selectedValue: 'feed 0' | 'feed 1' | null) {
    this.hostSelectionService.setQuery({ feed: selectedValue });
  }
  private selectAsset(selectedValue: any) {
    this.hostSelectionService.setQuery({ asset: selectedValue });
  }

  private selectPeriod(selectedValue: any) {
    this.hostSelectionService.setQuery({ date: selectedValue });
  }

  private getHosts(selectedValue: any) {
    return this.hostSelectionService.getHosts(selectedValue).pipe(
      tap((hosts) => {
        if (hosts.length > 0) {
          this.hostPlaceholder =
            this.translateService.instant('host_placeholder');
          this.formGroup.get('host')?.enable();
        } else {
          this.hostPlaceholder = this.translateService.instant('no_values');
          this.formGroup.get('host')?.disable();
        }
      }),
      catchError(() => {
        this.hostPlaceholder = this.translateService.instant('no_values');
        this.formGroup.get('host')?.disable();
        return EMPTY;
      })
    );
  }

  private pushAllSelectedQueryCalls() {
    if (this.queryDate) {
      this.queryCalls.push(defer(() => of(this.selectPeriod(this.queryDate))));
    }
    if (this.queryOrganisation) {
      this.queryCalls.push(
        defer(() => of(this.selectOrganization(this.queryOrganisation)))
      );
    }
    if (this.queryHost) {
      this.queryCalls.push(defer(() => of(this.selectHost(this.queryHost))));
    }
    if (this.queryOutlet) {
      this.queryCalls.push(
        defer(() => of(this.selectHostOutlet(this.queryOutlet)))
      );
    }
    if (this.queryFeed) {
      this.queryCalls.push(defer(() => of(this.selectFeed(this.queryFeed))));
    }
    if (this.queryAsset) {
      this.queryCalls.push(defer(() => of(this.selectAsset(this.queryAsset))));
    }
  }
}
