<div color="primary" class="header">
  <div class="headerElement left">
    <button mat-icon-button (click)="toggle()">
      <mat-icon>menu</mat-icon>
    </button>
    <span>{{ "main_title" | translate }}</span>
  </div>
  <div class="headerElement right">
    <div>
      <ng-container *ngIf="this.authService.isAuthenticated">
        <button mat-icon-button (click)="logout()">
          <mat-icon>logout</mat-icon>
        </button>
      </ng-container>
    </div>
  </div>
</div>
