import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import {
  BehaviorSubject,
  EMPTY,
  forkJoin,
  Observable,
  of,
  switchMap,
  tap,
} from 'rxjs';
import { Host } from '../model/host';
import { HostOutlet } from '../model/host-outlet';
import { Organization } from '../model/organization';
import { AuthMe } from '../model/auth-me';
import { SelectQuery } from '../model/selectQuery';
import { Feed } from '../model/feed';
import { Asset } from '../model/asset';

@Injectable({
  providedIn: 'root',
})
export class HostSelectionService {
  private readonly _query = new BehaviorSubject<SelectQuery>({
    organisation: null,
    host: null,
    outlet: null,
    feed: null,
    asset: null,
    outletVoltage: null,
    outletVoltageTHD: null,
    date: 'today',
  });

  public readonly query$ = this._query.asObservable();

  constructor(private http: HttpClient) {}

  private get query(): SelectQuery {
    return this._query.getValue();
  }

  private set query(val: Partial<SelectQuery>) {
    if (
      val.organisation === undefined &&
      val.host === undefined &&
      val.outlet === undefined &&
      val.asset === undefined &&
      val.feed === undefined &&
      val.outletVoltage === undefined &&
      val.outletVoltageTHD === undefined
    ) {
      this._query.next({
        organisation: null,
        host: null,
        outlet: null,
        feed: null,
        asset: null,
        outletVoltage: null,
        outletVoltageTHD: null,
        date: this.query.date,
      });
    }
    if (val.organisation !== undefined) {
      this._query.next({
        organisation: val.organisation,
        host: null,
        outlet: null,
        feed: null,
        asset: null,
        outletVoltage: null,
        outletVoltageTHD: null,
        date: this.query.date,
      });
    }
    if (val.host !== undefined) {
      this._query.next({
        organisation: this.query.organisation,
        host: val.host,
        outlet: null,
        feed: null,
        asset: null,
        outletVoltage: null,
        outletVoltageTHD: null,
        date: this.query.date,
      });
    }
    if (val.outlet !== undefined) {
      this._query.next({
        organisation: this.query.organisation,
        host: this.query.host,
        outlet: val.outlet,
        feed: null,
        asset: null,
        outletVoltage: null,
        outletVoltageTHD: null,
        date: this.query.date,
      });
    }
    if (val.asset !== undefined) {
      this._query.next({
        organisation: this.query.organisation,
        host: this.query.host,
        outlet: this.query.outlet,
        asset: val.asset,
        feed: null,
        outletVoltage: null,
        outletVoltageTHD: null,
        date: this.query.date,
      });
    }
    if (val.feed !== undefined) {
      this._query.next({
        organisation: this.query.organisation,
        host: this.query.host,
        outlet: this.query.outlet,
        asset: this.query.asset,
        feed: val.feed,
        outletVoltage: null,
        outletVoltageTHD: null,
        date: this.query.date,
      });
    }
    if (val.outletVoltage !== undefined) {
      this._query.next({
        ...this.query,
        outletVoltage: val.outletVoltage,
      });
    }
    if (val.outletVoltageTHD !== undefined) {
      this._query.next({
        ...this.query,
        outletVoltageTHD: val.outletVoltageTHD,
      });
    }
    if (val.date !== undefined) {
      this._query.next({
        ...this.query,
        date: val.date,
      });
    }
  }

  public setQuery(val: Partial<SelectQuery>) {
    this.query = val;
  }

  public organisations$: Observable<Organization[]>;
  public hosts$: Observable<Host[]>;
  public hostOutlets$: Observable<HostOutlet[]>;
  public feeds$: Observable<{ name: string; value: string }[]>;
  public assets$: Observable<Asset[]>;
  public allAssets$: Observable<Asset[]>;

  private _organisationsCache: Organization[] | null = null;
  private _hostsCache: Host[] | null = null;
  private _hostOutletsCache: HostOutlet[] | null = null;
  private _feedsCache: Feed[] | null = null;
  private _allAssetsCache: Asset[] | null = null;

  public deleteChildCache(
    selectionLevel: 'organisation' | 'host' | 'feed' | 'asset'
  ) {
    switch (selectionLevel) {
      case 'organisation':
        this._hostsCache = null;
        this._hostOutletsCache = null;
        this._feedsCache = null;
        this._allAssetsCache = null;
        this.hosts$ = EMPTY;
        this.hostOutlets$ = EMPTY;
        this.feeds$ = EMPTY;
        this.assets$ = EMPTY;
        this.allAssets$ = EMPTY;
        break;
      case 'host':
        this._hostOutletsCache = null;
        this._feedsCache = null;
        this._allAssetsCache = null;
        this.hostOutlets$ = EMPTY;
        this.feeds$ = EMPTY;
        this.assets$ = EMPTY;
        this.allAssets$ = EMPTY;
        break;
      case 'feed':
        this._allAssetsCache = null;
        // this.assets$ = EMPTY;
        // this.allAssets$ = EMPTY;
        break;
      case 'asset':
        //TODO: We can probably keep the caches below
        // this._feedsCache = null;
        // this.feeds$ = EMPTY;
        break;
    }
  }

  public getOrganisations(): Observable<Organization[]> {
    if (this._organisationsCache) {
      return of(this._organisationsCache);
    }

    return this.getAuthMe().pipe(
      switchMap((authMe) => {
        const organisationObservables = authMe.groups.map((group) =>
          this.getOrganisation(group)
        );

        return forkJoin(organisationObservables);
      }),
      tap((organisations) => {
        this._organisationsCache = organisations;
      })
    );
  }

  public getHosts(
    hostGroupId: string,
    page = 1,
    limit = 30
  ): Observable<Host[]> {
    if (this._hostsCache) {
      return of(this._hostsCache);
    }

    return this.http
      .get<Host[]>('/hosts?feed=false', {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
        }),
        params: new HttpParams()
          .set('page', page)
          .set('limit', limit)
          .set('hostGroupId', hostGroupId),
      })
      .pipe(
        tap((hosts) => {
          this._hostsCache = hosts;
        })
      );
  }

  public getHostOutlets(
    hostId: number,
    page = 1,
    limit = 30
  ): Observable<HostOutlet[]> {
    if (this._hostOutletsCache) {
      return of(this._hostOutletsCache);
    }
    return this.http
      .get<HostOutlet[]>(`/hosts/${hostId}/outlets?feed=false`, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
        }),
        params: new HttpParams()
          .set('page', page)
          .set('limit', limit)
          .set('hostGroupId', this._query.value.organisation?.toString() ?? ''),
      })
      .pipe(
        tap((hostOutlets) => {
          this._hostOutletsCache = hostOutlets;
        })
      );
  }

  public getFeeds(
    hostId: number,
    feed = true,
    page = 1,
    limit = 30
  ): Observable<Feed[]> {
    if (this._feedsCache) {
      return of(this._feedsCache);
    }
    return this.http
      .get<Feed[]>(`/hosts/${hostId}/rack-feed-discovery?feed=${feed}`, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
        }),
        params: new HttpParams()
          .set('page', page)
          .set('limit', limit)
          .set('hostGroupId', this._query.value.organisation?.toString() ?? ''),
      })
      .pipe(
        tap((feeds) => {
          this._feedsCache = feeds;
        })
      );
  }

  public getAllAssets(
    hostId: number,
    page = 1,
    limit = 30
  ): Observable<Asset[]> {
    if (this._allAssetsCache) {
      return of(this._allAssetsCache);
    }
    return this.http
      .get<Asset[]>(`/hosts/${hostId}/asset-discovery`, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
        }),
        params: new HttpParams()
          .set('page', page)
          .set('limit', limit)
          .set('hostGroupId', this._query.value.organisation?.toString() ?? '')
          .set('assetParameter', '')
          .set('feed', 'false'),
      })
      .pipe(
        tap((assets) => {
          this._allAssetsCache = assets;
        })
      );
  }

  public getOrganisation(organizationsId: string): Observable<Organization> {
    return this.http.get<Organization>('/organizations/' + organizationsId, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    });
  }

  public getAuthMe(): Observable<AuthMe> {
    return this.http.get<AuthMe>('/auth/me', {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    });
  }
}
