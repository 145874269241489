import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './auth/guards/auth.guard';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'dashboard',
    pathMatch: 'full',
  },
  {
    path: 'auth',
    loadChildren: () => import('./auth/auth.module').then((m) => m.AuthModule),
  },
  {
    path: 'dashboard',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./dashboard/dashboard.module').then((m) => m.DashboardModule),
  },
  {
    path: 'power-quality',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./power-quality/power-quality.module').then(
        (m) => m.PowerQualityModule
      ),
  },
  {
    path: 'kpi-scores',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./kpi-scores/kpi-scores.module').then((m) => m.KpiScoresModule),
  },
  // {
  //   path: 'settings',
  //   canActivate: [AuthGuard],
  //   loadChildren: () =>
  //     import('./settings/settings.module').then((m) => m.SettingsModule),
  // },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
