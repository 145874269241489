import { Component, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';

/*
  Generic component to help unsubscribe subscriptions

  Just add `takeUntil(this.destroy$)` to your subscriptions
  and this component will handle the rest
*/
@Component({
  template: '',
})
export class DestroyComponent implements OnDestroy {
  public destroy$ = new Subject();

  ngOnDestroy(): void {
    this.destroy$.next(null);
    this.destroy$.complete();
  }
}
