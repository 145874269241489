<div [formGroup]="formGroup" class="selection-menu flex flex-row">
  <mat-form-field
    subscriptSizing="dynamic"
    matFormFieldAppearance="outline"
    *ngIf="
      showSelectors.includes('organisation') && {
        organisations: this.hostSelectionService.organisations$ | async
      } as organisationsObj
    "
  >
    <mat-label>
      <app-header-selection-placeholder
        [placeholder]="
          getOrganisationPlaceholder(
            organisationsObj.organisations?.length !== 0
          )
        "
        [loading]="!organisationsObj.organisations"
      ></app-header-selection-placeholder>
    </mat-label>
    <mat-select
      #organisationSelect
      formControlName="organisation"
      (selectionChange)="onSelectOrganization($event.value)"
    >
      <mat-option [value]="null">-Select-</mat-option>
      <mat-option
        *ngFor="let org of organisationsObj.organisations"
        [value]="org.groupId"
      >
        {{ org.name }}
      </mat-option>
    </mat-select>
  </mat-form-field>

  <mat-form-field
    subscriptSizing="dynamic"
    *ngIf="
      showSelectors.includes('host') && {
        hosts: this.hostSelectionService.hosts$ | async
      } as hostObj
    "
  >
    <mat-label>
      <app-header-selection-placeholder
        [placeholder]="hostPlaceholder"
        [loading]="
          !hostObj.hosts &&
          !this.formGroup.get('host')?.disabled &&
          !!this.formGroup.get('organisation')?.value
        "
      ></app-header-selection-placeholder>
    </mat-label>
    <mat-select
      formControlName="host"
      (selectionChange)="onSelectHost($event.value)"
    >
      <mat-option [value]="null">-Select-</mat-option>
      <mat-option *ngFor="let host of hostObj.hosts" [value]="host.id">
        {{ host.name }}
      </mat-option>
    </mat-select>
  </mat-form-field>

  <mat-form-field
    subscriptSizing="dynamic"
    *ngIf="
      showSelectors.includes('outlet') && {
        hostOutlets: this.hostSelectionService.hostOutlets$ | async
      } as hostOutletsObj
    "
  >
    <mat-label>
      <app-header-selection-placeholder
        [placeholder]="outletPlaceholder"
        [loading]="
          !hostOutletsObj.hostOutlets &&
          !this.formGroup.get('outlet')?.disabled &&
          !!this.formGroup.get('host')?.value &&
          !!this.formGroup.get('organisation')?.value
        "
      ></app-header-selection-placeholder>
    </mat-label>
    <mat-select
      formControlName="outlet"
      (selectionChange)="onSelectHostOutlet($event.value)"
    >
      <mat-option [value]="null">-Select-</mat-option>

      <mat-option
        *ngFor="
          let hostOutlet of this.hostSelectionService.hostOutlets$ | async
        "
        [value]="hostOutlet.outletName"
      >
        {{ hostOutlet.outletName }}
      </mat-option>
    </mat-select>
  </mat-form-field>

  <mat-form-field
    subscriptSizing="dynamic"
    *ngIf="
      showSelectors.includes('feed') && {
        feeds: this.hostSelectionService.feeds$ | async
      } as feedObj
    "
  >
    <mat-label>
      <app-header-selection-placeholder
        [placeholder]="feedPlaceholder"
        [loading]="
          !feedObj.feeds &&
          !this.formGroup.get('feed')?.disabled &&
          !!this.formGroup.get('organisation')?.value
        "
      ></app-header-selection-placeholder>
    </mat-label>
    <mat-select
      formControlName="feed"
      (selectionChange)="onSelectFeed($event.value)"
    >
      <mat-option [value]="null">-Select-</mat-option>
      <!--  -->
      <mat-option *ngFor="let feed of feedObj.feeds" [value]="feed.value">
        {{ feed.name }}
      </mat-option>
    </mat-select>
  </mat-form-field>

  <mat-form-field
    subscriptSizing="dynamic"
    *ngIf="
      showSelectors.includes('asset') && {
        assets: this.hostSelectionService.assets$ | async
      } as assetObj
    "
  >
    <mat-label>
      <app-header-selection-placeholder
        [placeholder]="assetPlaceholder"
        [loading]="
          !assetObj.assets &&
          !this.formGroup.get('asset')?.disabled &&
          !!this.formGroup.get('organisation')?.value
        "
      ></app-header-selection-placeholder>
    </mat-label>
    <mat-select
      formControlName="asset"
      (selectionChange)="onSelectAsset($event.value)"
    >
      <mat-option [value]="null">-Select-</mat-option>
      <mat-option *ngFor="let asset of assetObj.assets" [value]="asset.id">
        {{ asset.name }}
      </mat-option>
    </mat-select>
  </mat-form-field>

  <mat-form-field
    subscriptSizing="dynamic"
    *ngIf="showSelectors.includes('date')"
  >
    <div class="date-selector" (click)="dateSelect.open()">
      <mat-icon class="date-icon">calendar_month</mat-icon>
      <mat-select
        #dateSelect
        formControlName="date"
        (selectionChange)="onSelectPeriod($event.value)"
      >
        <mat-option
          *ngFor="let period of periodKeys"
          [value]="period | translate"
        >
          {{ period | translate }}
        </mat-option>
      </mat-select>
    </div>
  </mat-form-field>
</div>
