import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { tap } from 'rxjs';
import { User } from '../interfaces/user.interface';
import { jwtDecode } from 'jwt-decode';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private readonly tokenName = 'jwtToken';
  private readonly refreshTokenName = 'jwtRefreshToken';

  public isAuthenticated = false;

  constructor(
    private readonly http: HttpClient,
    private readonly router: Router
  ) {
    this.isAuthenticated = this.hasValidToken();
  }

  public login(email: string, password: string) {
    return this.http
      .post<{ token: string; refreshToken: string }>(`/auth/login`, {
        email,
        password,
      })
      .pipe(
        tap(({ token, refreshToken }) => {
          this.setToken(token);
          this.setRefreshToken(refreshToken);
          this.isAuthenticated = true;
        })
      );
  }

  public getUserInfo() {
    return this.http.get<User>(`/auth/me`).pipe(
      tap((data) => {
        this.isAuthenticated = !!data.userSid;
      })
    );
  }

  public logout() {
    this.clearTokens();
    this.router.navigate(['/auth/login']);
    this.isAuthenticated = false;
  }

  public setToken(token: string) {
    localStorage.setItem(this.tokenName, token);
  }

  public setRefreshToken(refreshToken: string) {
    localStorage.setItem(this.refreshTokenName, refreshToken);
  }

  public getToken() {
    return localStorage.getItem(this.tokenName);
  }

  public getRefreshToken() {
    return localStorage.getItem(this.refreshTokenName);
  }

  public hasValidToken() {
    const token = this.getToken();
    if (!token) {
      return false;
    }

    const decoded: { exp: number } = jwtDecode(token);
    return new Date().getTime() < decoded.exp * 1000;
  }

  public clearTokens() {
    localStorage.removeItem(this.tokenName);
    localStorage.removeItem(this.refreshTokenName);
  }

  public refreshToken() {
    return this.http
      .post<{ token: string; refreshToken: string }>(`/auth/refresh-token`, {
        refreshToken: this.getRefreshToken(),
      })
      .pipe(
        tap(({ token, refreshToken }) => {
          this.setToken(token);
          this.setRefreshToken(refreshToken);
        })
      );
  }
}
