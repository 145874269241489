import {
  ChangeDetectionStrategy,
  Component,
  ContentChild,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output,
  TemplateRef,
  ViewEncapsulation,
} from '@angular/core';
import { animate, style, transition, trigger } from '@angular/animations';
import { scaleLinear, scalePoint, scaleTime } from 'd3-scale';
import { curveLinear } from 'd3-shape';

import {
  BaseChartComponent,
  calculateViewDimensions,
  ColorHelper,
  getScaleType,
  getUniqueXDomainValues,
  id,
  LegendOptions,
  LegendPosition,
  Orientation,
  ScaleType,
  ViewDimensions,
} from '@swimlane/ngx-charts';
import { isPlatformServer } from '@angular/common';

@Component({
  selector: 'ngx-charts-double-line-chart',
  templateUrl: './ngx-charts-double-line-chart.component.html',
  styleUrls: ['./ngx-charts-double-line-chart.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('animationState', [
      transition(':leave', [
        style({
          opacity: 1,
        }),
        animate(
          500,
          style({
            opacity: 0,
          })
        ),
      ]),
    ]),
  ],
})
export class NgxChartsDoubleLineChartComponent
  extends BaseChartComponent
  implements OnInit
{
  @Input() legend: boolean;
  @Input() legendTitle: string = 'Legend';
  @Input() legendPosition: LegendPosition = LegendPosition.Right;
  @Input() xAxis: boolean;
  @Input() yAxis: boolean;
  @Input() showXAxisLabel: boolean;
  @Input() yMainAxisShowLabel: boolean;
  @Input() ySecondaryAxisShowLabel: boolean;
  @Input() xAxisLabel: string;
  @Input() yMainAxisLabel: string;
  @Input() ySecondaryAxisLabel: string;
  @Input() autoScale: boolean;
  @Input() timeline: boolean;
  @Input() gradient: boolean;
  @Input() showXGridLines = true;
  @Input() showYMainGridLines = true;
  @Input() showYSecondaryGridLines = false;
  @Input() curve: any = curveLinear;
  @Input() activeEntries: any[] = [];
  @Input() override schemeType: ScaleType;
  @Input() rangeFillOpacity: number;
  @Input() xAxisTickFormatting: any;
  @Input() yMainAxisTickFormatting: any;
  @Input() ySecondaryAxisTickFormatting: any;
  @Input() xAxisTicks: any[];
  @Input() yMainAxisTicks: any[];
  @Input() ySecondaryAxisTicks: any[];
  @Input() roundDomains: boolean = false;
  @Input() tooltipDisabled: boolean = false;
  @Input() showRefLines: boolean = false;
  @Input() referenceLines: any;
  @Input() showRefLabels: boolean = true;
  @Input() xScaleMin: number;
  @Input() xScaleMax: number;
  @Input() yMainScaleMin: number;
  @Input() yMainScaleMax: number;
  @Input() ySecondaryScaleMin: number;
  @Input() ySecondaryScaleMax: number;
  @Input() trimXAxisTicks: boolean = true;
  @Input() trimYAxisTicks: boolean = true;
  @Input() rotateXAxisTicks: boolean = true;
  @Input() maxXAxisTickLength: number = 16;
  @Input() maxYAxisTickLength: number = 16;
  @Input('results') chartsData: any;
  @Input() yMainAxisScaleFactor: any;
  @Input() ySecondaryAxisScaleFactor: any;
  @Input() secondYAxisName: string;

  @Output() activate: EventEmitter<any> = new EventEmitter();
  @Output() deactivate: EventEmitter<any> = new EventEmitter();

  @ContentChild('tooltipTemplate') tooltipTemplate: TemplateRef<any>;
  @ContentChild('seriesTooltipTemplate')
  seriesTooltipTemplate: TemplateRef<any>;
  dims: ViewDimensions;
  xSet: any;
  xDomain: any;
  yDomain: [number, number] | any;
  seriesDomain: any;
  yScale: any;
  yMainScale: any;
  xScale: any;
  colors: ColorHelper;
  colorsLine: ColorHelper;
  scaleType: ScaleType;
  transform: string;
  clipPath: string;
  clipPathId: string;
  areaPath: any;
  margin: number[] = [10, 20, 10, 20];
  hoveredVertical: any;
  xAxisHeight: number = 0;
  yAxisWidth: number = 0;
  filteredDomain: any;
  legendOptions: any;
  hasRange: boolean;
  xScaleLine: any;
  yScaleLine: any;
  ySecondScale: any;
  yDomainLine: any;
  yDomainLine1: any;
  scaledAxis: any;
  combinedSeries: any;
  yOrientLeft = Orientation.Left;
  yOrientRight = Orientation.Right;
  legendSpacing = 0;
  bandwidth: any;
  barPadding = 10;
  lineChart: any;
  lineChart1: any;
  timelineWidth: any;
  timelineHeight: number = 50;
  timelineXScale: any;
  timelineYScale: any;
  timelineXDomain: any;
  timelineTransform: any;
  timelinePadding: number = 10;
  isSSR = false;
  comboArr: any[] = [];

  override ngOnInit() {
    if (isPlatformServer(this.platformId)) {
      this.isSSR = true;
    }
  }
  dataSplit() {
    [this.lineChart, this.lineChart1] = this.chartsData.reduce(
      (acc: any, item: any) => {
        if (item.name === this.secondYAxisName) {
          acc[1].push(item);
        } else {
          acc[0].push(item);
        }

        return acc;
      },
      [[], []]
    );

    if (this.lineChart1.length) {
      let [min1, max1] = this.getYDomainLine(this.lineChart);
      let [min2, max2] = this.getYDomainLine(this.lineChart1);

      this.comboArr = this.lineChart.concat(
        this.lineChart1.map((line: any) => {
          return {
            name: line.name,
            series: line.series.map((point: any) => {
              return {
                name: point.name,
                value:
                  ((point.value - min2) * (max1 - min1)) / (max2 - min2) + min1,
              };
            }),
          };
        })
      );
    } else {
      this.comboArr = this.lineChart;
    }
  }

  trackBy(index: any, item: any): string {
    return item.name;
  }

  override update(): void {
    this.dataSplit();

    super.update();

    let q = {
      width: this.width,
      height: this.height,
      margins: this.margin,
      showXAxis: this.xAxis,
      showYAxis: this.yAxis,
      xAxisHeight: this.xAxisHeight,
      yAxisWidth: this.yAxisWidth,
      showXLabel: this.showXAxisLabel,
      showYLabel: this.yMainAxisShowLabel,
      showLegend: this.legend,
      legendType: this.schemeType,
    };

    this.dims = calculateViewDimensions(q);

    if (this.yAxis && this.lineChart1.length) {
      this.dims.width -= 65;
    }

    if (this.timeline) {
      this.dims.height -=
        this.timelineHeight + this.margin[2] + this.timelinePadding;
    }

    if (!this.yAxis) {
      this.legendSpacing = 0;
    } else if (this.yMainAxisShowLabel && this.yAxis) {
      this.legendSpacing = 100;
    } else {
      this.legendSpacing = 40;
    }

    this.xDomain = this.getXDomainLine();

    this.xScaleLine = this.getXScale(this.xDomain, this.dims.width);
    this.seriesDomain = this.getSeriesDomain();
    this.yDomainLine = this.getYDomainLine(this.lineChart);
    this.yMainScale = this.getYScaleLine(this.yDomainLine, this.dims.height);

    if (this.lineChart1.length) {
      this.yDomainLine1 = this.getYDomainLine(this.lineChart1);
      this.ySecondScale = this.getYScaleLine(
        this.yDomainLine1,
        this.dims.height
      );
    }

    this.updateTimeline();
    this.setColors();
    this.legendOptions = this.getLegendOptions();
    this.transform = `translate(${this.dims.xOffset} , ${this.margin[0]})`;
    this.clipPathId = 'clip' + id().toString();
    this.clipPath = `url(#${this.clipPathId})`;
  }

  deactivateAll() {
    this.activeEntries = [...this.activeEntries];
    for (const entry of this.activeEntries) {
      this.deactivate.emit({ value: entry, entries: [] });
    }
    this.activeEntries = [];
  }

  @HostListener('mouseleave')
  hideCircles(): void {
    this.hoveredVertical = null;
    this.deactivateAll();
  }

  updateHoveredVertical(item: any): void {
    this.hoveredVertical = item.value;
    this.deactivateAll();
  }

  updateDomain(domain: any): void {
    this.filteredDomain = domain;
    this.xDomain = this.filteredDomain;
    this.xScaleLine = this.getXScale(this.xDomain, this.dims.width);
  }

  getSeriesDomain(): any[] {
    this.combinedSeries = this.lineChart.concat(this.lineChart1);

    return this.combinedSeries.map((d: any) => d.name);
  }

  isDate(value: any): boolean {
    return value instanceof Date;
  }

  getXDomainLine(): any[] {
    let values: any[] = [];

    for (const results of this.lineChart) {
      for (const d of results.series) {
        if (!values.includes(d.name)) {
          values.push(d.name);
        }
      }
    }

    this.scaleType = getScaleType(values);
    let domain = [];

    if (this.scaleType === 'time') {
      const min = Math.min(...values);
      const max = Math.max(...values);
      domain = [min, max];
    } else if (this.scaleType === 'linear') {
      values = values.map((v) => Number(v));
      const min = Math.min(...values);
      const max = Math.max(...values);
      domain = [min, max];
    } else {
      domain = values;
    }

    this.xSet = values;

    return domain;
  }

  getYDomainLine(data: any): any[] {
    const domain = [];

    for (const results of data) {
      for (const d of results.series) {
        if (domain.indexOf(d.value) < 0) {
          domain.push(d.value);
        }
        if (d.min !== undefined) {
          if (domain.indexOf(d.min) < 0) {
            domain.push(d.min);
          }
        }
        if (d.max !== undefined) {
          if (domain.indexOf(d.max) < 0) {
            domain.push(d.max);
          }
        }
      }
    }

    if (!this.autoScale) {
      domain.push(0);
    }

    let min = this.yMainScaleMin ? this.yMainScaleMin : Math.min(...domain);
    let max = this.yMainScaleMax ? this.yMainScaleMax : Math.max(...domain);

    if (this.ySecondaryAxisScaleFactor) {
      const minMax = this.ySecondaryAxisScaleFactor(min, max);

      return [minMax.min, minMax.max];
    } else {
      return [min, max];
    }
  }

  getXScale(domain: any, width: any): any {
    let scale;
    if (this.bandwidth === undefined) {
      this.bandwidth = this.dims.width - this.barPadding;
    }

    if (this.scaleType === 'time') {
      scale = scaleTime().range([0, width]).domain(domain);
    } else if (this.scaleType === 'linear') {
      scale = scaleLinear().range([0, width]).domain(domain);

      if (this.roundDomains) {
        scale = scale.nice();
      }
    } else if (this.scaleType === 'ordinal') {
      scale = scalePoint()
        .range([this.bandwidth / 2, width - this.bandwidth / 2])
        .domain(domain);
    }

    return scale;
  }

  getYScaleLine(domain: any, height: any): any {
    const scale = scaleLinear().range([height, 0]).domain(domain);

    return this.roundDomains ? scale.nice() : scale;
  }

  getYScale(yDomain: any, height: any): any {
    const scale = scaleLinear().range([height, 0]).domain(yDomain);

    return this.roundDomains ? scale.nice() : scale;
  }

  onClick(data: any) {
    this.select.emit(data);
  }

  setColors(): void {
    let domain;
    if (this.schemeType === 'ordinal') {
      domain = this.xDomain;
    } else {
      domain = this.yDomain;
    }
    this.colors = new ColorHelper(
      this.scheme,
      this.schemeType,
      domain,
      this.customColors
    );
    this.colorsLine = new ColorHelper(
      this.scheme,
      this.schemeType,
      domain,
      this.customColors
    );
  }

  getLegendOptions() {
    const opts = {
      scaleType: this.schemeType as any,
      colors: undefined as any,
      domain: [],
      title: undefined as any,
    };
    if (opts.scaleType === ScaleType.Ordinal) {
      opts.domain = this.seriesDomain;
      opts.colors = this.colors;
      opts.title = this.legendTitle;
    } else {
      opts.domain = this.seriesDomain;
      opts.colors = this.colors.scale;
    }

    return opts;
  }

  updateLineWidth(width: any): void {
    this.bandwidth = width;
  }

  updateYAxisWidth({ width }: { width: number }): void {
    this.yAxisWidth = width + 20;
    this.update();
  }

  updateXAxisHeight({ height }: { height: number }): void {
    this.xAxisHeight = height;
    this.update();
  }

  onActivate(item: any) {
    const idx = this.activeEntries.findIndex((d) => {
      return (
        d.name === item.name &&
        d.value === item.value &&
        d.series === item.series
      );
    });
    if (idx > -1) {
      return;
    }

    this.activeEntries = [item, ...this.activeEntries];
    this.activate.emit({ value: item, entries: this.activeEntries });
  }

  onDeactivate(item: any) {
    const idx = this.activeEntries.findIndex((d) => {
      return (
        d.name === item.name &&
        d.value === item.value &&
        d.series === item.series
      );
    });

    this.activeEntries.splice(idx, 1);
    this.activeEntries = [...this.activeEntries];

    this.deactivate.emit({ value: item, entries: this.activeEntries });
  }

  updateTimeline(): void {
    if (this.timeline) {
      this.timelineWidth = this.dims.width;
      this.timelineXDomain = this.getXDomainLine();
      this.timelineXScale = this.getXScale(
        this.timelineXDomain,
        this.timelineWidth
      );
      let timeLineDomain = this.getYDomainLine(this.comboArr);
      this.timelineYScale = this.getYScale(timeLineDomain, this.timelineHeight);
      this.timelineTransform = `translate(${this.dims.xOffset}, ${-this
        .margin[2]})`;
    }
  }
}
