import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { AuthService } from './auth/services/auth.service';
import { filter, switchMap, takeUntil, tap } from 'rxjs/operators';
import { BreakpointObserver } from '@angular/cdk/layout';
import { MatSidenav } from '@angular/material/sidenav';
import { DestroyComponent } from 'src/app/core/components/destroy/destroy.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { HealthService } from './services/health.service';
import { TranslateService } from '@ngx-translate/core';
import { timer } from 'rxjs';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent
  extends DestroyComponent
  implements OnInit, AfterViewInit
{
  @ViewChild(MatSidenav) sidenav: MatSidenav | undefined;

  public constructor(
    public authService: AuthService,
    public translateService: TranslateService,
    private observer: BreakpointObserver,
    private snackBar: MatSnackBar,
    private healthService: HealthService
  ) {
    super();
  }
  ngOnInit(): void {
    //Health check every 10 minutes
    timer(0, 600000)
      .pipe(
        takeUntil(this.destroy$),
        filter(() => this.authService.isAuthenticated),
        switchMap(() => this.healthService.checkHealth()),
        filter((healthError) => healthError)
      )
      .subscribe(() => {
        this.snackBar.open(
          this.translateService.instant('health_error_text'),
          this.translateService.instant('ok'),
          {
            duration: 15000,
            horizontalPosition: 'right',
            verticalPosition: 'bottom',
          }
        );
      });
  }

  ngAfterViewInit() {
    this.observer
      .observe(['(max-width: 950px)'])
      .pipe(
        takeUntil(this.destroy$),
        filter(() => !!this.sidenav)
      )
      .subscribe((res) => {
        if (res.matches) {
          (<MatSidenav>this.sidenav).mode = 'over';
          (<MatSidenav>this.sidenav).close();
        } else {
          (<MatSidenav>this.sidenav).mode = 'side';
          (<MatSidenav>this.sidenav).open();
        }
      });
  }

  sidenavToggled() {
    window.dispatchEvent(new Event('resize'));
  }
}
