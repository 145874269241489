import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../services/auth.service';

@Injectable()
export class AuthGuard  {
  constructor(
    private authService: AuthService,
    private readonly router: Router
  ) {}

  canActivate() {
    const isAuthenticated = this.authService.isAuthenticated;
    if (!isAuthenticated) {
      this.router.navigate(['auth/login']);
    }
    return isAuthenticated;
  }
}
