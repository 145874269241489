import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DataStateComponent } from './data-state/data-state.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { NgxChartsDoubleLineChartComponent } from './ngx-extensions/ngx-charts-double-line-chart/ngx-charts-double-line-chart.component';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { HeaderComponent } from './header/header.component';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { TranslateModule } from '@ngx-translate/core';
import { ReactiveFormsModule } from '@angular/forms';
import { SideNavComponent } from './side-nav/side-nav.component';
import { RouterModule } from '@angular/router';
import { HeaderSelectionPlaceholderComponent } from './header/header-selection-placeholder/header-selection-placeholder.component';
import { SelectionMenuComponent } from './selection-menu/selection-menu.component';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    ReactiveFormsModule,
    NgxChartsModule,
    MatProgressSpinnerModule,
    MatToolbarModule,
    MatIconModule,
    MatButtonModule,
    MatFormFieldModule,
    MatSelectModule,
    TranslateModule,
  ],
  declarations: [
    DataStateComponent,
    HeaderComponent,
    SideNavComponent,
    NgxChartsDoubleLineChartComponent,
    HeaderSelectionPlaceholderComponent,
    SelectionMenuComponent,
  ],
  exports: [
    DataStateComponent,
    HeaderComponent,
    SideNavComponent,
    NgxChartsDoubleLineChartComponent,
    SelectionMenuComponent,
  ],
})
export class SharedModule {}
