<ng-container>
  <button
    mat-button
    class="menu-button"
    routerLink="/dashboard"
    routerLinkActive="active"
    queryParamsHandling="preserve"
  >
    <mat-icon>home</mat-icon>
    <span>{{ "main_navigation_dashboard" | translate }}</span>
  </button>
  <button
    mat-button
    class="menu-button"
    routerLink="/power-quality"
    routerLinkActive="active"
    queryParamsHandling="preserve"
  >
    <mat-icon>battery_charging_full</mat-icon>
    <span>{{ "main_navigation_power_quality" | translate }}</span>
  </button>
  <button
    mat-button
    class="menu-button"
    routerLink="/kpi-scores"
    routerLinkActive="active"
    queryParamsHandling="preserve"
  >
    <mat-icon>trending_up</mat-icon>
    <span>{{ "main_navigation_services" | translate }}</span>
  </button>
  <!-- <button
    mat-button
    class="menu-button"
    routerLink="/settings"
    routerLinkActive="active"
    queryParamsHandling="preserve"
  >
    <mat-icon>settings</mat-icon>
    <span>{{ "main_navigation_settings" | translate }}</span>
  </button> -->
</ng-container>
