import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class HealthService {
  constructor(private http: HttpClient) {}

  public checkHealth() {
    return this.http
      .get<{ status: string; dependencies: Object[] }>('/health', {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
        }),
      })
      .pipe(
        map(
          (healthData: { status: string; dependencies: Object[] }) =>
            healthData.status !== 'pass'
        )
      );
  }
}
