import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SelectionHiderService {
  private isOutletSelectorHidden = new BehaviorSubject<boolean>(false);

  hideOutletSelector(value: boolean) {
    this.isOutletSelectorHidden.next(value);
  }

  getIsOutletSelectorHidden() {
    return this.isOutletSelectorHidden.asObservable();
  }
}
