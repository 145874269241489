<div class="flex flex-column flex-fill flex-align-center flex-justify-center">
  <mat-card appearance="outlined" class="login-form">
    <form [formGroup]="form" (submit)="login()">
      <mat-card-content class="flex flex-column">
        <div class="flex flex-align-center flex-justify-center">
          <img
            class="logo"
            src="/assets/logo.png"
            alt="{{ 'main_title' | translate }}"
          />
        </div>
        <div>
          <mat-error>{{ errorText }}</mat-error>
        </div>
        <mat-form-field class="flex-fill-width">
          <input
            matInput
            placeholder="{{ 'username' | translate }}"
            formControlName="email"
          />
          <mat-error>{{ emailErrorText }}</mat-error>
        </mat-form-field>
        <mat-form-field class="flex-fill-width">
          <input
            matInput
            type="password"
            placeholder="{{ 'password' | translate }}"
            formControlName="password"
          />
          <mat-error>{{ passwordErrorText }}</mat-error>
        </mat-form-field>
        <button mat-raised-button color="primary" class="btn-block">
          <span class="button-text">
            {{ "login" | translate }}
            <mat-spinner
              *ngIf="loading"
              class="login-loader"
              [diameter]="18"
            ></mat-spinner>
          </span>
        </button>
      </mat-card-content>
    </form>
  </mat-card>
</div>
