<div *ngIf="state === StateType.Loading" style="height: 200px">
  <div class="flex-center">
    <mat-spinner></mat-spinner>
  </div>
</div>

<div *ngIf="state === StateType.Data">
  <ng-content></ng-content>
</div>

<div *ngIf="state === StateType.NoData" style="height: 200px">
  <div class="flex-center no-data-text">
    <mat-icon>error_outline</mat-icon>
    <p *ngIf="isOrganisationSelected">{{ "no_data" | translate }}</p>
    <p *ngIf="!isOrganisationSelected">{{ "select-group" | translate }}</p>
  </div>
</div>

<div *ngIf="state === StateType.Error" style="height: 200px">
  <div class="flex-center error-text">
    <mat-icon>error_outline</mat-icon>
    <p>{{ "error" | translate }}</p>
  </div>
</div>

<div *ngIf="state === StateType.IdleNoData" style="height: 200px">
  <div class="flex-center no-data-text">
    <mat-icon>error_outline</mat-icon>
    <p>{{ "idle_no_data" | translate }}</p>
  </div>
</div>

<div
  *ngIf="state === StateType.IdleChangePerformanceMode"
  style="height: 200px"
>
  <div class="flex-center error-text">
    <mat-icon>error_outline</mat-icon>
    <p>{{ "idle_change_performance_mode" | translate }}</p>
  </div>
</div>

<div *ngIf="state === StateType.IdleStandBy" style="height: 200px">
  <div class="flex-center error-text">
    <mat-icon>error_outline</mat-icon>
    <p>{{ "idle_stand_by" | translate }}</p>
  </div>
</div>
