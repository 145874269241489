import { Component } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { take, takeUntil } from 'rxjs';
import { DestroyComponent } from 'src/app/core/components/destroy/destroy.component';
import { AuthService } from '../../services/auth.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent extends DestroyComponent {
  public errorText = '';
  public emailErrorText = this.translateService.instant('email_error');
  public passwordErrorText = this.translateService.instant('password_error');
  public loading: boolean = false;

  public form = this.formBuilder.group({
    email: [null, [Validators.required, Validators.email]],
    password: [null, Validators.required],
  });

  public constructor(
    private readonly formBuilder: UntypedFormBuilder,
    private readonly authService: AuthService,
    private readonly router: Router,
    private readonly translateService: TranslateService
  ) {
    super();

    if (this.authService.isAuthenticated) {
      this.router.navigate(['/']);
    }
  }

  public login() {
    this.errorText = '';

    if (!this.form.valid) {
      return;
    }

    this.loading = true;

    this.authService
      .login(this.form.get('email')!.value, this.form.get('password')!.value)
      .pipe(take(1), takeUntil(this.destroy$))
      .subscribe({
        next: () => {
          this.loading = false;
          this.router.navigate(['/']);
        },
        error: () => {
          this.errorText = this.translateService.instant('login_error');
          this.loading = false;
        },
      });
  }
}
