import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-header-selection-placeholder',
  templateUrl: './header-selection-placeholder.component.html',
  styleUrls: ['./header-selection-placeholder.component.scss'],
})
export class HeaderSelectionPlaceholderComponent {
  @Input() placeholder: string;
  @Input() loading: boolean;
}
